<template>
  <div
    class="p-4 rounded-2xl border border-outlineGray hover:bg-mint"
    @mouseenter="hovered = true"
    @mouseleave="hovered = false"
  >
    <p class="font-bold text-xl text-dark-800">{{ title }}</p>
    <hr v-if="!hovered" class="my-3" />
    <div class="my-3" v-else>
      <div class="w-2/5 h-2 bg-secondary-strawberry rounded-full mr-auto"></div>
    </div>
    <p
      class=""
      :class="hovered ? ' transition-transform transform translate-y-1' : ''"
    >
      <span v-if="header" class="font-semibold"> {{ header }} </span>
      {{ content }}
    </p>
  </div>
</template>

<script setup>
import { ref } from "vue";
defineProps({
  title: {
    type: String,
    default: "",
  },
  header: {
    type: String,
    default: "",
  },
  content: {
    type: String,
    default: "",
  },
});
const hovered = ref(false);
</script>

<style lang="scss" scoped></style>
