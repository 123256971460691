<template>
  <main class="">
    <div class="pb-20 md:pb-6">
      <!-- SIDE BAR //// -->

      <nav
        class="pt-5 md:pt-7 pl-5 md:pl-28 no-scrollbar items-center justify-between z-20 md:w-60 block fixed left-0 top-0 bottom-0 flex-row flex-nowrap overflow-y-auto"
      >
        <div
          class="flex-col items-stretch min-h-full px-0 flex flex-wrap w-full sticky p-0 mx-auto"
        >
          <div>
            <img src="@/assets/icons/apiLogo.svg" alt="" />
          </div>
          <div class="mt-16 sidebar">
            <div
              class="flex gap-5 text-primary font-bold py-4 border-t border-outlineGray text-sm"
            >
              <img src="@/assets/icons/apihome.svg" class="w-5 h-5" alt="" />

              Home
            </div>
            <ul class="pl-10 text-xs">
              <li @click="scrollToSection('quick-start')">Quick Start</li>
              <li @click="scrollToSection('services', 'paye')">PAYE</li>
              <li @click="scrollToSection('services', 'pension')">Pension</li>
              <li @click="scrollToSection('services', 'micro pension')">
                Micro-Pension
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <div class="relative ml-6 bg-white onprint min-h-screen">
        <!-- NAVBAR ////// -->
        <nav
          class="sticky top-0 left-0 w-full z-10 bg-white bg-opacity-70 backdrop-blur-3xl flex md:flex-row md:flex-nowrap md:justify-start items-center p-2"
        >
          <div
            class="w-full mx-auto items-center flex justify-end flex-wrap py-1 pr-3 md:pr-32"
          >
            <div v-if="!isLoggedIn" class="flex items-center gap-10">
              <p
                class="text-primary cursor-pointer"
                @click="$router.push('/login')"
              >
                Sign in
              </p>
              <easiButton
                block
                color="newGreen"
                rounded="3xl"
                @click="$router.push('/register')"
              >
                <span class="font-semibold"> Create an account </span>
              </easiButton>
            </div>
            <p
              v-else
              class="text-primary cursor-pointer mt-3"
              @click="$router.push('/developers/dashboard/overview')"
            >
              Dashboard
            </p>
          </div>
        </nav>
        <!-- CONTENT /// -->
        <div
          class="mx-auto w-full pb-10 pl-32 md:pl-64 grid grid-cols-10 gap-12"
        >
          <div class="col-span-10 md:col-span-7 overflow-auto no-scrollbar">
            <div class="content">
              <!-- SECTIONS //// -->
              <section id="quick-start" class="pt-10">
                <p class="text-secondary text-sm font-medium">
                  Eazipay developer documentation
                </p>
                <p class="font-bold text-4xl text-primary-hover my-4">
                  Quick Start
                </p>

                <!-- Quick Start content goes here -->
                The Eazipay API can help you automate your tax payment
                processing, reduce errors, and improve efficiency. It is a
                convenient and affordable solution for businesses of all sizes.

                <img src="./assets/quickstart.gif" alt="Quick Start" class="" />

                <p class="text-2xl font-bold text-dark-800 mb-6">
                  Follow these steps to Get Started
                </p>
                <div class="grid grid-cols-2 gap-6">
                  <ApiDocumentationCard
                    title="Step 1"
                    header="Create an
                  account:"
                    content=" To access the Eazipay API, you will need
                  to create an account and obtain an API key. You can do this by
                  clicking on the 'Sign Up' button."
                  />
                  <ApiDocumentationCard
                    title="Step 2"
                    header="Complete KYC:"
                    content=" We value your trust and security at Eazipay. To ensure the highest level of safety and compliance, we kindly request you to complete your KYC (Know Your Customer) verification on our developer's dashboard"
                  />
                  <ApiDocumentationCard
                    title="Step 3"
                    content="Once you have created an account, you can now obtain an API key"
                  />
                  <ApiDocumentationCard
                    title="Step 4"
                    content="Choose the api endpoint you want to use"
                  />
                  <ApiDocumentationCard
                    title="Step 5"
                    content="Integrate the API with your existing systems."
                  />
                  <ApiDocumentationCard
                    title="Step 6"
                    content="To authenticate your requests to the Eazipay API, you must include your API key in the Authorisation header "
                  />
                </div>
              </section>
              <section id="services" class="pt-20">
                <!-- PAYE content goes here -->

                <p class="font-bold text-3xl text-primary-hover mb-6">
                  Our API Services
                </p>
                <!-- TABS /// -->
                <div
                  class="bg-newAsh border border-outlineGray rounded-xl p-2 mb-20"
                >
                  <div class="flex gap-1">
                    <div
                      v-for="(tab, tabIndex) in tabs"
                      :key="tabIndex"
                      class="py-2 font-medium text-sm rounded-lg uppercase cursor-pointer w-full text-center"
                      :class="
                        activeTab === tab
                          ? 'bg-white text-black'
                          : ' text-newGray'
                      "
                      @click="activeTab = tab"
                    >
                      {{ tab }}
                    </div>
                  </div>
                </div>

                <div v-if="activeTab === 'paye'">
                  <img src="./assets/paye.gif" alt="Paye" class="mx-auto" />
                  <p class="font-bold text-2xl text-dark-800 mb-4">Overview</p>
                  <p>
                    Eazipay PAYE API allows developers to integrate with
                    Eazipay's PAYE system to automate the payment of PAYE taxes
                    to the government. This can save developers a significant
                    amount of time and effort, ensure that their clients' PAYE
                    taxes are always paid on time and in full, and allows
                    developers to integrate PAYE tax processing into their
                    applications.
                  </p>
                  <p class="font-bold text-2xl text-dark-800 mt-10 mb-4">
                    Key Features
                  </p>
                  <div class="flex flex-col gap-6">
                    <ApiFeatureCard
                      content="Our PAYE API streamlines the integration of Paye services into
                  your applications, reducing complexity and development time."
                    />
                    <ApiFeatureCard
                      content="Receipt generation with session ID"
                    />
                    <ApiFeatureCard content="Auto calculation of PAYE" />
                    <ApiFeatureCard content="PAYE Data retrieval" />
                    <ApiFeatureCard
                      content="The ability to track PAYE payments"
                    />
                  </div>
                </div>
                <div v-else-if="activeTab === 'pension'">
                  <img
                    src="./assets/pension.gif"
                    alt="Pension"
                    class="mx-auto"
                  />
                  <p class="font-bold text-2xl text-dark-800 mb-4">Overview</p>
                  <p>
                    Eazipay Pension API is a simple and easy-to-use API that
                    allows developers to integrate pension functionality into
                    their own applications.
                  </p>
                  <p class="font-bold text-2xl text-dark-800 mt-10 mb-4">
                    Key Features
                  </p>
                  <div class="flex flex-col gap-6">
                    <ApiFeatureCard
                      content="Eazipay Pension API can generate receipts for pension contributions, which can be used by users to prove their contributions to tax authorities or other bodies."
                    />
                 
                    <div class="grid grid-cols-2 gap-6">
                      <ApiFeatureCard
                        content="Retrieving  pension information."
                      />
                      <ApiFeatureCard content="Auto calculates for pensions." />
                    </div>
                  </div>
                </div>
                <div v-else-if="activeTab === 'micro pension'">
                  <img
                    src="./assets/pension.gif"
                    alt="Pension"
                    class="mx-auto"
                  />
                  <p class="font-bold text-2xl text-dark-800 mb-4">Overview</p>
                  <p>
                    Eazipay Micro Pension API is designed to simplify micro
                    pension integration for your applications and platforms.
                    <br />
                    With our API, you can effortlessly calculate micro-pension
                    information. Additionally, you can retrieve valuable data
                    and generate receipts, all with the convenience of session
                    IDs
                  </p>
                  <p class="font-bold text-2xl text-dark-800 mt-10 mb-4">
                    Key Features
                  </p>
                  <div class="flex flex-col gap-6">
                    <ApiFeatureCard
                      header="Simplified Integration:"
                      content=" Our Micro Pension API streamlines the integration of micro pension services into your applications, reducing complexity and development time."
                    />
                    <ApiFeatureCard
                      header="Auto-Calculation of Micro Pension:"
                      content="Auto-Calculation of Micro Pension: Automatically calculate Micro pension contributions based on user inputs, eliminating manual calculations and reducing errors."
                    />
                    <ApiFeatureCard
                      header="Micro Pension Integration:"
                      content="Seamlessly integrate micro pension features into your platform, providing users with a convenient and accessible way to plan for their future."
                    />
                    <ApiFeatureCard
                      header="Data Retrieval:"
                      content=" Access micro pension information through API endpoints, ensuring you have the data you need to provide valuable insights to your users."
                    />
                    <ApiFeatureCard
                      header="Receipt Generation with Session ID:"
                      content="Generate detailed receipts for micro-pension transactions, all conveniently associated with unique session IDs for easy reference."
                    />
                  </div>
                </div>
              </section>
              <section class="mt-10">
                <a
                  href="https://eazipay.readme.io/reference/getting-started-with-your-api-1"
                  target="_blank"
                  class="font-bold text-2xl"
                >
                  <span class="text-info-light">  API REFERENCE  </span>
                </a>

                <div class="mt-20 bg-newAsh p-6 rounded-2xl questions">
                  <p class="font-bold text-xl text-dark-800 mb-4">
                    Have any questions?
                  </p>
                  <p class="mb-3">
                    Got questions about our API or how to use it effectively?
                    We’re here to help!
                  </p>
                  <p>Contact Information:</p>
                  <ul>
                    <li>
                      • Email: [

                      <a
                        href="mailto:hi@myeazipay.com"
                        target="_blank"
                        class="text-info-light cursor-pointer"
                      >
                        hi@myeazipay.com
                      </a>

                      ]
                    </li>
                    <li>
                      • Phone: [
                      <a
                        href="tel:09134766185"
                        target="_blank"
                        class="text-info-light cursor-pointer"
                      >
                        +234 913 476 6185</a
                      >
                      ]
                    </li>
                    <li>
                      • FAQ: Check out our [
                      <router-link
                        class="text-info-light cursor-pointer"
                        to="/admin/support/talk-to-us"
                        >FAQ Section</router-link
                      >
                      ] for quick answers to common queries.
                    </li>
                  </ul>
                </div>
              </section>
            </div>
          </div>
          <div
            class="hidden relative col-span-3 h-screen justify-center md:flex flex-col bg-white"
          >
            <div
              class="pt-20 fixed top-60 w-52 cursor-pointer"
              @click="
                isLoggedIn
                  ? $router.push('/developers/dashboard/overview')
                  : $router.push('/register')
              "
            >
              <img src="@/assets/icons/ready.svg" alt="" />

              <p class="my-2 font-normal text-sm text-grey">
                Ready to getsss started? Create a free account now.
              </p>

              <div class="flex gap-3">
                <p class="text-secondary font-bold text-sm">Get Started</p>
                <img src="@/assets/icons/api-arrow-small-right.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script setup>
import ApiDocumentationCard from "@/components/ApiDashboard/ApiDocumentationCard.vue";
import ApiFeatureCard from "@/components/ApiDashboard/ApiFeatureCard.vue";
import { useDataStore } from "@/stores/data.js";

import { computed, ref } from "vue";
const store = useDataStore();
const isLoggedIn = computed(() => store.getIsLoggedIn);
const activeSection = ref("quick-start"); // Initialize with 'home' as the default active section
const tabs = ["paye", "pension", "micro pension"];
const activeTab = ref("paye");

const scrollToSection = (sectionId, tab) => {
  activeSection.value = sectionId; // Update the active section
  const section = document.getElementById(sectionId);
  if (sectionId === "quick-start") {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  } else {
    section.scrollIntoView({ behavior: "smooth" });
  }
  if (tab) {
    activeTab.value = tab;
  }
};

// const handleScroll = () => {
//   const sectionElements = document.querySelectorAll(".content section");
//   for (const section of sectionElements) {
//     const rect = section.getBoundingClientRect();
//     if (rect.top <= 100 && rect.bottom >= 100) {
//       activeSection.value = section.id;
//       break; // Stop when the first visible section is found
//     }
//   }
// };

// onMounted(() => {
//   window.addEventListener("scroll", handleScroll);
// });

// onUnmounted(() => {
//   window.removeEventListener("scroll", handleScroll);
// });
</script>

<style lang="css" scoped>
.active {
  font-weight: bold;
  color: #007bff; /* Customize the active link style as needed */
}
.sidebar ul li:not(:last-child) {
  border-bottom: 1px solid #e7e8e7;
}
.sidebar ul li:first-child {
  border-top: 1px solid #e7e8e7;
}
.sidebar ul li {
  padding: 16px 0px;
  cursor: pointer;
}

.sidebar ul li:hover {
  color: #ea4e4b;
}
</style>
